import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/homelayout';
import SEO from '../components/seo';
// import "swiper/swiper.scss";
import Arrow from '../images/arrow.inline.svg';

function IndexPage({ pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO title="Contact" image="https://www.leverid.com/og-image.jpg" />
      <Container className="contact-above">
        <Row>
          <Col>
            <h1>Let's talk!</h1>
          </Col>
        </Row>
      </Container>
      <Container className="contactpage fullwidth">
        <Container>
          <Row>
            <Col>
              <h2>Lever ID OÜ</h2>
              <h4>Address:</h4>
              <p>A. H. Tammsaare tee 47, Tallinn 11316, Estonia</p>

              <h4>Business inquiries:</h4>
              <p>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </p>
              <p>
                <a href="tel:+372 65 65 600">+372 65 65 600</a>
              </p>

              <h4>Media requests:</h4>
              <p>
                <a href="mailto:media@leverid.com">media@leverid.com</a>
              </p>
            </Col>
            <Col>
              <form
                name="Contact"
                className="contactform"
                action="https://getform.io/f/797a4426-458a-4736-af99-5ce35ab5e067"
                method="POST"
              >
                <div className="order-form">
                  <Row>
                    <Col>
                      <label>
                        <p>First & last name</p>
                        <input type="text" name="firstnamelastname" required />
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <p>Email address</p>
                        <input type="email" name="email" required />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        <p>Organization</p>
                        <input type="text" name="organization" required />
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <p>Phone number (optional)</p>
                        <input type="text" name="phonenumber" />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        <p>Message</p>
                        <textarea name="message" required></textarea>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button type="submit">
                        Submit
                        <Arrow />
                      </button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export default IndexPage;
